<template>
  <div
    class="grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
  >
    <CategoryGridItem
      v-for="category in categories"
      :key="category.id"
      :category="category"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";

import CategoryGridItem from "../components/CategoryGridItem.vue";
import { useCategoryStore } from "../stores/category";

const categoryStore = useCategoryStore();
const { categories } = storeToRefs(categoryStore);
</script>
