import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";

import AuthCallback from "./pages/AuthCallback.vue";
import HomePage from "./pages/HomePage.vue";
import ListsPage from "./pages/ListsPage.vue";
import LogoutPage from "./pages/LogoutPage.vue";
import ProductsPage from "./pages/ProductsPage.vue";
import ProfilePage from "./pages/ProfilePage.vue";
import SettingsPage from "./pages/SettingsPage.vue";
import StoresPage from "./pages/StoresPage.vue";

const routerHistory = createWebHistory();

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: "/",
      component: HomePage,
      beforeEnter: authGuard,
    },
    {
      path: "/auth/callback",
      component: AuthCallback,
    },
    {
      path: "/lists",
      component: ListsPage,
      beforeEnter: authGuard,
    },
    {
      path: "/logout",
      component: LogoutPage,
      beforeEnter: authGuard,
    },
    {
      path: "/products",
      component: ProductsPage,
      beforeEnter: authGuard,
    },
    {
      path: "/profile",
      component: ProfilePage,
      beforeEnter: authGuard,
    },
    {
      path: "/settings",
      component: SettingsPage,
      beforeEnter: authGuard,
    },
    {
      path: "/stores",
      component: StoresPage,
      beforeEnter: authGuard,
    },
  ],
});

export default router;
