<template>
  <div class="min-h-full">
    <div class="bg-manypenny-primary-dark pb-32">
      <Disclosure
        as="nav"
        class="bg-manypenny-secondary-dark"
        v-slot="{ open }"
      >
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="pt-2 pb-2">
            <div class="flex h-16 items-center justify-between px-4 sm:px-0">
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <img
                    class="h-8 w-8"
                    src="./images/manypenny-logo.svg"
                    alt="Your Company"
                  />
                </div>
                <div class="hidden md:block">
                  <div class="ml-10 flex items-baseline space-x-4">
                    <router-link
                      v-for="item in navigation"
                      :key="item.name"
                      :to="item.to"
                      :class="[
                        item.to === $route.path
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'rounded-md px-3 py-2 text-sm font-medium',
                      ]"
                      :aria-current="item.current ? 'page' : undefined"
                      >{{ item.name }}</router-link
                    >
                  </div>
                </div>
              </div>
              <div
                class="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end"
              >
                <div class="w-full max-w-lg lg:max-w-xs">
                  <label for="search" class="sr-only">Search</label>
                  <div
                    class="relative text-gray-400 focus-within:text-gray-600"
                  >
                    <div
                      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                    >
                      <MagnifyingGlassIcon class="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search"
                      class="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-manypenny-primary-light sm:text-sm sm:leading-6"
                      placeholder="Search"
                      type="search"
                      name="search"
                      v-on:click="searchOpen = true"
                    />
                  </div>
                </div>
              </div>
              <div class="hidden md:block">
                <div class="ml-4 flex items-center md:ml-6">
                  <button
                    type="button"
                    class="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span class="absolute -inset-1.5" />
                    <span class="sr-only">View notifications</span>
                    <BellIcon class="h-6 w-6" aria-hidden="true" />
                  </button>

                  <!-- Profile dropdown -->
                  <Menu as="div" class="relative ml-3">
                    <div>
                      <MenuButton
                        class="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span class="absolute -inset-1.5" />
                        <span class="sr-only">Open user menu</span>
                        <img
                          v-if="
                            userStore?.details?.Picture &&
                            userStore?.details?.Picture.includes('.manypenny.')
                          "
                          class="h-8 w-8 rounded-full"
                          :src="userStore?.details?.Picture"
                          alt=""
                        />
                        <span
                          v-else
                          class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-manypenny-primary-light"
                        >
                          <span
                            class="text-sm font-medium leading-none text-white"
                            >{{ initials }}</span
                          >
                        </span>
                      </MenuButton>
                    </div>
                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <MenuItems
                        class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <MenuItem
                          v-for="item in userNavigation"
                          :key="item.name"
                          v-slot="{ active }"
                        >
                          <router-link
                            :to="item.to"
                            :class="[
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700',
                            ]"
                            >{{ item.name }}</router-link
                          >
                        </MenuItem>
                      </MenuItems>
                    </transition>
                  </Menu>
                </div>
              </div>
              <div class="-mr-2 flex md:hidden">
                <!-- Mobile menu button -->
                <DisclosureButton
                  class="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span class="absolute -inset-0.5" />
                  <span class="sr-only">Open main menu</span>
                  <Bars3Icon
                    v-if="!open"
                    class="block h-6 w-6"
                    aria-hidden="true"
                  />
                  <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                </DisclosureButton>
              </div>
            </div>
          </div>
        </div>

        <DisclosurePanel class="border-b border-gray-700 md:hidden">
          <div class="space-y-1 px-2 py-3 sm:px-3">
            <DisclosureButton
              v-for="item in navigation"
              :key="item.name"
              as="a"
              :href="item.href"
              :class="[
                item.current
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'block rounded-md px-3 py-2 text-base font-medium',
              ]"
              :aria-current="item.current ? 'page' : undefined"
              >{{ item.name }}</DisclosureButton
            >
          </div>
          <div class="border-t border-gray-700 pb-3 pt-4">
            <div class="flex items-center px-5">
              <div class="flex-shrink-0">
                <img
                  v-if="
                    userStore?.details?.Picture &&
                    userStore?.details?.Picture.includes('.manypenny.')
                  "
                  class="h-10 w-10 rounded-full"
                  :src="userStore?.details?.Picture"
                  alt=""
                />
                <span
                  v-else
                  class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-manypenny-primary-light"
                >
                  <span class="text-sm font-medium leading-none text-white">{{
                    initials
                  }}</span>
                </span>
              </div>
              <div class="ml-3">
                <div class="text-base font-medium leading-none text-white">
                  {{ userStore?.details?.Name }}
                </div>
                <div class="text-sm font-medium leading-none text-gray-400">
                  {{ userStore?.details?.Email }}
                </div>
              </div>
              <button
                type="button"
                class="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              >
                <span class="absolute -inset-1.5" />
                <span class="sr-only">View notifications</span>
                <BellIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="mt-3 space-y-1 px-2">
              <DisclosureButton
                v-for="item in userNavigation"
                :key="item.name"
                as="router-link"
                :to="item.to"
                class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                >{{ item.name }}</DisclosureButton
              >
            </div>
          </div>
        </DisclosurePanel>
      </Disclosure>
      <header class="py-10" v-if="$route.path === '/'">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold tracking-tight text-white">
            {{ getGreetingTime(new Date())
            }}{{
              !isEmpty(get(userStore, "details"))
                ? `, ${get(
                    userStore,
                    "details.GivenName",
                    get(userStore, "details.Name"),
                  )}`
                : ""
            }}
            👋
          </h1>
        </div>
      </header>
      <header class="py-10" v-else>
        <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div
            class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8"
          >
            <div class="grid grid-cols-1 gap-4 lg:col-span-2">
              <h1 class="text-3xl font-bold tracking-tight text-white">
                {{ $route.path }}
              </h1>
            </div>
            <div class="grid grid-cols-1 gap-4">
              {{ geolocationStoreAddress }}
            </div>
          </div>
        </div>
      </header>
    </div>
    <router-view />
  </div>

  <TransitionRoot
    :show="searchOpen"
    as="template"
    @after-leave="query = ''"
    appear
  >
    <Dialog as="div" class="relative z-10" @close="searchOpen = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"
        />
      </TransitionChild>

      <div
        class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-2xl transform divide-y divide-gray-500 divide-opacity-10 overflow-hidden rounded-xl bg-white bg-opacity-80 shadow-2xl ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter transition-all"
          >
            <Combobox @update:modelValue="onSelect">
              <div class="relative">
                <MagnifyingGlassIcon
                  class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-900 text-opacity-40"
                  aria-hidden="true"
                />
                <ComboboxInput
                  class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  @change="query = $event.target.value"
                />
              </div>

              <ComboboxOptions
                v-if="query === '' || filteredProjects.length > 0"
                static
                class="max-h-80 scroll-py-2 divide-y divide-gray-500 divide-opacity-10 overflow-y-auto"
              >
                <li class="p-2">
                  <h2
                    v-if="query === ''"
                    class="mb-2 mt-4 px-3 text-xs font-semibold text-gray-900"
                  >
                    Recent searches
                  </h2>
                  <ul class="text-sm text-gray-700">
                    <ComboboxOption
                      v-for="project in query === ''
                        ? recent
                        : filteredProjects"
                      :key="project.id"
                      :value="project"
                      as="template"
                      v-slot="{ active }"
                    >
                      <li
                        :class="[
                          'flex cursor-default select-none items-center rounded-md px-3 py-2',
                          active && 'bg-gray-900 bg-opacity-5 text-gray-900',
                        ]"
                      >
                        <FolderIcon
                          :class="[
                            'h-6 w-6 flex-none text-gray-900 text-opacity-40',
                            active && 'text-opacity-100',
                          ]"
                          aria-hidden="true"
                        />
                        <span class="ml-3 flex-auto truncate">{{
                          project.name
                        }}</span>
                        <span v-if="active" class="ml-3 flex-none text-gray-500"
                          >Jump to...</span
                        >
                      </li>
                    </ComboboxOption>
                  </ul>
                </li>
              </ComboboxOptions>

              <div
                v-if="query !== '' && filteredProjects.length === 0"
                class="px-6 py-14 text-center sm:px-14"
              >
                <FolderIcon
                  class="mx-auto h-6 w-6 text-gray-900 text-opacity-40"
                  aria-hidden="true"
                />
                <p class="mt-4 text-sm text-gray-900">
                  We couldn't find any projects with that term. Please try
                  again.
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { get, isEmpty } from "lodash";
import { watch } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { storeToRefs } from "pinia";
import { useGeolocation } from "vue-composable";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import getGreetingTime from "greeting-time";
import { computed, ref } from "vue";
import {
  DocumentPlusIcon,
  FolderIcon,
  FolderPlusIcon,
  HashtagIcon,
  TagIcon,
} from "@heroicons/vue/24/outline";

import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { reverseGeocode } from "./services/api";
import { useCategoryStore } from "./stores/category";
import { useGeolocationStore } from "./stores/geolocation";
import { useUserStore } from "./stores/user";

const projects = [
  { id: 1, name: "Workflow Inc. / Website Redesign", url: "#" },
  // More projects...
];
const recent = [projects[0]];

const quickActions = [
  { name: "Add new file...", icon: DocumentPlusIcon, shortcut: "N", url: "#" },
  { name: "Add new folder...", icon: FolderPlusIcon, shortcut: "F", url: "#" },
  { name: "Add hashtag...", icon: HashtagIcon, shortcut: "H", url: "#" },
  { name: "Add label...", icon: TagIcon, shortcut: "L", url: "#" },
];

const searchOpen = ref(false);
const query = ref("");
const filteredProjects = computed(() =>
  query.value === ""
    ? []
    : projects.filter((project) => {
        return project.name.toLowerCase().includes(query.value.toLowerCase());
      }),
);

function onSelect(item) {
  window.location = item.url;
}

const navigation = [
  { name: "Home", to: "/", current: true },
  { name: "Lists", to: "/lists", current: false },
  { name: "Products", to: "/products", current: false },
  { name: "Stores", to: "/stores", current: false },
];

const userNavigation = [
  { name: "Your Profile", to: "/profile" },
  { name: "Settings", to: "/settings" },
  { name: "Sign out", to: "/logout" },
];

const { isAuthenticated } = useAuth0();

const {
  coords: geolocationCoords,
  error: geolocationError,
  refresh: geolocationRefresh,
  supported: geolocationSupported,
} = useGeolocation({
  immediate: false,
});

// Log wether geolocation is supported
console.log("Is GeoLocation supported?", geolocationSupported ? "Yes" : "No");

const categoryStore = useCategoryStore();
const geolocationStore = useGeolocationStore();
const userStore = useUserStore();

const { details: userStoreDetails, initials } = storeToRefs(userStore);
const {
  address: geolocationStoreAddress,
  allowed: geolocationStoreAllowed,
  coords: geolocationStoreCoords,
} = storeToRefs(geolocationStore);

geolocationStore.supported = geolocationSupported;

watch(geolocationCoords, async (newVal) => {
  console.log("GeoLocation Coords changed to", newVal);
  if (newVal) {
    geolocationStore.coords = newVal;
  }
});

watch(geolocationError, async (newVal) => {
  console.log("GeoLocation Error changed to", newVal);
  if (newVal) {
    geolocationStore.error = newVal;
  }
});

watch(geolocationStoreAddress, async (newVal) => {
  console.log("GeoLocationStore Address changed to", newVal);
});

watch(geolocationStoreAllowed, async (newVal) => {
  console.log("GeoLocationStore Allowed changed to", newVal);
  if (newVal && geolocationStore.allowed && geolocationStore.supported) {
    geolocationRefresh();
  }
});

watch(geolocationStoreCoords, async (newVal) => {
  console.log("GeoLocationStore Coords changed to", newVal);
  const location = await reverseGeocode(newVal.latitude, newVal.longitude);
  if (location?.formatted_address) {
    geolocationStore.address = location?.formatted_address;
  }
});

watch(isAuthenticated, async (newVal) => {
  console.log("IsAuthenticated changed to", newVal);
  if (newVal) {
    await Promise.all([
      categoryStore.fetchCategories(),
      userStore.fetchDetails(),
      userStore.fetchFavouriteProducts(),
      userStore.fetchFavouriteStores(),
      userStore.fetchLists(),
    ]);
  }
});

watch(userStoreDetails, async (newVal) => {
  // Geolocation is allowed if the user has set the WebappGeoLocation flag
  geolocationStore.allowed = newVal?.UserMetadata?.WebappGeoLocation === true;
});
</script>
