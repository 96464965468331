import posthog from "posthog-js";
import * as Sentry from "@sentry/vue";
import { createPinia } from "pinia";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faBaby,
  faBottleWater,
  faCanFood,
  faClothesHanger,
  faCookieBite,
  faCroissant,
  faEgg,
  faGlobeStand,
  faHouseTree,
  faJugBottle,
  faOutlet,
  faPaw,
  faPie,
  faPlateUtensils,
  faSnowflake,
  faSprayCanSparkles,
  faStopwatch20,
  faSuitcaseMedical,
  faTeddyBear,
  faTomato,
  faToothbrush,
  faWreath,
} from "@fortawesome/pro-solid-svg-icons";

import router from "./router";
import App from "./App.vue";

import auth0 from "./plugins/auth0";
import { createMetadata } from "./plugins/metadata";

import "./css/style.css";

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en: {},
    fr: {},
  },
});

const app = createApp(App);

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    attachStacktrace: true,
    attachProps: true,
  });
}

if (import.meta.env.PROD) {
  posthog.init(import.meta.env.VITE_POSTHOG_PROJECT_API_KEY, {
    api_host: import.meta.env.VITE_POSTHOG_INSTANCE_ADDRESS,
  });
}

library.add(
  faBaby,
  faBottleWater,
  faCanFood,
  faClothesHanger,
  faCookieBite,
  faCroissant,
  faEgg,
  faGlobeStand,
  faHouseTree,
  faJugBottle,
  faOutlet,
  faPaw,
  faPie,
  faPlateUtensils,
  faSnowflake,
  faSprayCanSparkles,
  faStopwatch20,
  faSuitcaseMedical,
  faTeddyBear,
  faTomato,
  faToothbrush,
  faWreath,
);

app.use(router);
app.use(createPinia());
app.use(i18n);

app.use(auth0);
app.use(createMetadata());

app.component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
