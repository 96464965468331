import axios from "axios";
import { backOff } from "exponential-backoff";
import localforage from "localforage";
import set from "lodash.set";

import { getAccessToken } from "../plugins/auth0";
import { useGeolocationStore } from "../stores/geolocation";

const baseURL = import.meta.env.VITE_API_URL;
const etagHeader = import.meta.env.VITE_API_ETAG_HEADER;
// const newsletterOrigin = import.meta.env.VITE_NEWSLETTER_ORIGIN;
const positionHeader = import.meta.env.VITE_API_POSITION_HEADER;
const totalResultsHeader = import.meta.env.VITE_API_TOTAL_RESULTS_HEADER;

// Initialize localForage
const cache = localforage.createInstance({
  name: "manypenny",
  storeName: "api",
});

// Initialize axios with default config
const api = axios.create({
  baseURL,
  timeout: 15000,
});

// Helper function to generate a SHA-256 hash
const generateHash = async (input) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hash = await crypto.subtle.digest("SHA-256", data);
  return Array.from(new Uint8Array(hash))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
};

// Helper function to generate a cache key based on the URL and its parameters
const generateCacheKey = async (config) =>
  generateHash(
    JSON.stringify({
      method: config.method,
      url: config.url,
      data: config.data || null,
      params: config.params || null,
    }),
  );

// Interceptor to include Auth0 Bearer token
api.interceptors.request.use(
  async (config) => {
    try {
      const token = await getAccessToken();
      set(config, "headers.Authorization", `Bearer ${token}`);
    } catch (error) {
      console.error("Error adding Auth0 Bearer token:", error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.request.use(
  async (config) => {
    if (config.method !== "get") {
      return config;
    }
    // Generate cache key
    config.cacheKey = await generateCacheKey(config);
    // Check localForage for an ETag for this cache key
    const etag = await cache.getItem(`${config.cacheKey}:etag`);
    if (etag) {
      // Set the X-If-None-Match header
      config.headers["X-If-None-Match"] = etag;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(async (response) => {
  if (response && response.headers && response.headers[positionHeader]) {
    const geolocationStore = useGeolocationStore();
    const position = String(response.headers[positionHeader]).split(",");
    if (position && position.length === 2) {
      geolocationStore.approximatePosition = position;
    }
  }
  return response;
});

// Response interceptor
api.interceptors.response.use(
  async (response) => {
    if (response.config.cacheKey) {
      const etag = response.headers[etagHeader];
      if (etag) {
        // Store the ETag and response data in localForage
        await cache.setItem(`${response.config.cacheKey}:etag`, etag);
        await cache.setItem(`${response.config.cacheKey}:data`, response.data);
      }
    }
    return response;
  },
  async (error) => {
    if (
      error.response &&
      error.response.status === 304 &&
      error.response.config.cacheKey
    ) {
      // If 304, retrieve cached data from localForage
      error.response.data = await cache.getItem(
        `${error.response.config.cacheKey}:data`,
      );
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  },
);

// Function to add the localized helper method
const addLocalizedMethod = (data) => {
  if (Array.isArray(data)) {
    // Add the helper method to each item in the array
    data.forEach((item) => addLocalizedMethod(item));
  } else if (typeof data === "object") {
    // Add the localized helper method to this object
    if (data.Localized) {
      data.localized = function (lang) {
        let localizedData = this.Localized?.find(
          (item) => item.Language === lang,
        );

        if (!localizedData) {
          localizedData = this.Localized?.find(
            (item) => item.Language === "eng",
          );
        }

        if (!localizedData) {
          localizedData = this.Localized?.[0] || null;
        }

        return localizedData;
      };
    }

    // Recursively apply to all properties
    for (const key of Object.keys(data)) {
      addLocalizedMethod(data[key]);
    }
  }
};

// Response Interceptor to add helper methods
api.interceptors.response.use(
  (response) => {
    if (!response.data) {
      return response;
    }

    // Apply the localized helper method recursively
    addLocalizedMethod(response.data);

    // Add a method to get the integer-parsed value of the 'X-Total-Results' header
    response.data.getTotalResults = function () {
      const totalResults = response.headers[totalResultsHeader];
      return totalResults ? parseInt(totalResults, 10) : null;
    };

    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// *

export const getAllCategories = async () => {
  // Find Grocery category in the first level of categories
  let rootCategoryId;
  const firstLevelCategories = await getCategories();
  if (firstLevelCategories && firstLevelCategories.length > 0) {
    // Get the identifier of the one category called 'Grocery'
    for (const category of firstLevelCategories) {
      if (category.localized("eng").Name === "Grocery") {
        rootCategoryId = category.id;
        break;
      }
    }
  }
  // Get the children of the root category
  const rootCategory = await getCategory(rootCategoryId);
  let categories = [];
  if (
    rootCategory &&
    rootCategory.Children &&
    rootCategory.Children.length > 0
  ) {
    categories = rootCategory.Children;
  }
  return categories;
};

export const getCategory = async (id) => {
  try {
    const response = await backOff(() => api.get(`/categories/${id}`));
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Category ${id}: ${error}`);
  }
};

export const getCategoryProducts = async (id, { limit = 25, offset = 0 }) => {
  try {
    const response = await backOff(() =>
      api.get(`/categories/${id}/products`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Category ${id} Products: ${error}`);
  }
};

export const getCategoryProductsCount = async (id) => {
  try {
    const data = await getCategoryProducts(id, { limit: 0, offset: 0 });
    return data.getTotalResults();
  } catch (error) {
    throw new Error(`Could not get Category ${id} Products Count: ${error}`);
  }
};

export const getCategories = async () => {
  // First level only
  try {
    const response = await backOff(() => api.get("/categories"));
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Categories: ${error}`);
  }
};

export const getFavouriteProducts = async (limit = 25, offset = 0) => {
  try {
    const response = await backOff(() =>
      api.get("/users/self/products", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Favourite Products: ${error}`);
  }
};

export const getAllFavouriteProducts = async () => {
  const limit = 25;
  let offset = 0;
  let totalResults = 0;
  let favouriteProducts = [];
  do {
    const response = await getFavouriteProducts(limit, offset);
    favouriteProducts = favouriteProducts.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  favouriteProducts = favouriteProducts.filter(
    (product, index, self) =>
      index === self.findIndex((p) => p.id === product.id),
  );
  return favouriteProducts;
};

export const getFavouriteStores = async (limit = 25, offset = 0) => {
  try {
    const response = await backOff(() =>
      api.get("/users/self/stores", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Favourite Stores: ${error}`);
  }
};

export const getAllFavouriteStores = async () => {
  const limit = 25;
  let offset = 0;
  let totalResults = 0;
  let favouriteStores = [];
  do {
    const response = await getFavouriteStores(limit, offset);
    favouriteStores = favouriteStores.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  favouriteStores = favouriteStores.filter(
    (store, index, self) => index === self.findIndex((s) => s.id === store.id),
  );
  return favouriteStores;
};

export const getLists = async (limit = 25, offset = 0) => {
  try {
    const response = await backOff(() =>
      api.get("/users/self/lists", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Lists: ${error}`);
  }
};

export const getAllLists = async () => {
  const limit = 25;
  let offset = 0;
  let totalResults = 0;
  let lists = [];
  do {
    const response = await getLists(limit, offset);
    lists = lists.concat(response);
    totalResults = response.getTotalResults();
    offset += limit;
  } while (offset < totalResults);
  lists = lists.filter(
    (list, index, self) => index === self.findIndex((l) => l.id === list.id),
  );
  return lists;
};

export const getMyDetails = async () => {
  try {
    const response = await backOff(() => api.get("/users/self"));
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Details: ${error}`);
  }
};

export const getProduct = async (id) => {
  try {
    const response = await backOff(() => api.get(`/products/${id}`));
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Product ${id}: ${error}`);
  }
};

export const getProducts = async (limit = 25, offset = 0) => {
  try {
    const response = await backOff(() =>
      api.get("/products", {
        params: {
          limit,
          offset,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `Could not get Products with Limit ${limit} and Offset ${offset}: ${error}`,
    );
  }
};

export const getProductImages = async (id, limit, offset) => {
  try {
    const response = await backOff(() =>
      api.get(`/products/${id}/images`, {
        params: {
          limit,
          offset,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `Could not get Product ${id} Images with Limit ${limit} and Offset ${offset}: ${error}`,
    );
  }
};

export const isProductFavourited = async (id) => {
  try {
    const response = await backOff(() => api.get(`/users/self/products/${id}`));
    return response.data;
  } catch (error) {
    throw new Error(
      `Could not check wether Product ${id} is Favourited: ${error}`,
    );
  }
};

export const reverseGeocode = async (latitude, longitude) => {
  try {
    const response = await backOff(() =>
      api.get("/utilities/geocoding/reverse", {
        params: {
          p: `${longitude},${latitude}`,
        },
      }),
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching reverse geocode: ${error}`);
  }
};

export const updateMyDetails = async (details) => {
  try {
    const response = await backOff(() => api.patch("/users/self", details));
    return response.status === 204;
  } catch (error) {
    throw new Error(`Could not update User Details: ${error}`);
  }
};
